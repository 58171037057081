'use strict'

import slick from 'slick-carousel';

jQuery(function () {
    $('.home-slider__slides').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 7000,
    });

    
    $('.home-slider__slides').on('init', function(event){
        console.log(event)
    });

    $('.home-slider__slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
       $('.home-slider__slide').removeClass('active');
    });
    $('.home-slider__slides').on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('.home-slider__nav').find('#current-slide-num').html(currentSlide + 1);
        $('.slick-slide[data-slick-index="'+currentSlide+'"]').find('.home-slider__slide').addClass('active');
    });


    //Slick Nav
    $('.slides-nav').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('prev')) {
            $('.home-slider__slides').slick('slickPrev');
        }
        if ($(this).hasClass('next')) {
            $('.home-slider__slides').slick('slickNext');
        }

    })
})